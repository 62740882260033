/******************************************************************
Body
*******************************************************************/
.itf-side-menu + .itf-body {
    margin: 0;
}
.itf-body {
    margin: 0 auto;
    padding: 1.5rem;
    width: 44rem;
    overflow: auto;
}

.itf-block {
    margin-bottom: 2.5rem;
}

.itf-note {
    font-size: small;
    p:last-child {
        margin-bottom: 0;
    }

    &.note-radio-list {
        p:last-child {
            margin-bottom: 1rem;
        }
    }

    &.note-radio {
        padding-left: $radio-note-inset;
    }
}

.itf-file-question-file {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0.5rem;
}

.itf-step-actions {
    position: relative;
    display: flex;
    gap: 0.5rem;
    margin-top: 3rem;
    justify-content: center;
}

.itf-fee-note-display {
    padding-left: 0.25rem;
    cursor: pointer;
    &:hover {
        filter: brightness(0.8);
    }
}

.itf-fee-note {
    font-size: 0.9rem;
    padding: 0 1.5rem !important;
    text-align: justify !important;
}
