$intouch-brand-1: #1ab394;
$intouch-brand-2: #1d2f42;
$attention-color: #d63384; //$pink from Bootstrap

$radio-note-inset: 1.7rem;

/**********************************************************************
Original Source;
    $enable-validation-icons: false;
    @import "./../../../node_modules/bootstrap/scss/bootstrap.scss";
***********************************************************************/
@import "./inputs";
@import "./form-validation";
@import "./address";
@import "./date-time-pickers";
@import "./table";
@import "./type";
@import "./utilities";
@import "./layout-side-menu";
@import "./layout-body";
@import "./modal";
@import "./media";

.itf-container {
    position: relative;
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        overflow-wrap: break-word;
    }

    svg {
        vertical-align: middle;
    }

    .itf-pbl {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        font-size: small;
        color: #777;
        opacity: 0.5;
        text-decoration: none;

        img {
            opacity: 0.3;
        }

        &:hover {
            opacity: 1;

            img {
                opacity: 1;
            }
        }
    }
}
