.itf-required {
    color: $attention-color;
}

.itf-invalid-answer {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    font-size: 0.83125rem;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}

.itf-invalid-answer + .itf-button {
    margin-top: 0.5rem;
}

input,
select,
textarea,
.itf-currency-input,
.itf-date-picker {
    + .itf-invalid-answer {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &.is-invalid {
        border: solid 1px $attention-color;
        border-bottom-left-radius: 0;
    }
}

.itf-yes-no {
    + .itf-invalid-answer {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &.is-invalid {
        border: solid 1px $attention-color;
        width: 7.5rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.itf-time-picker {
    .itf-time-separator.is-invalid {
        border-top: solid 1px $attention-color;
        border-bottom: solid 1px $attention-color;
    }
}

.itf-currency-input {
    > span.is-invalid {
        border-top: solid 1px $attention-color;
        border-left: solid 1px $attention-color;
        border-bottom: solid 1px $attention-color;
    }

    > .itf-text-input {
        border-left: solid 1px transparent;
    }
}

.itf-radio-option-other,
.itf-multi-select-other {
    > .itf-text-input.is-invalid {
        border-bottom-left-radius: 0.25rem;
    }
}
