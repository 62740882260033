.itf-button {
    display: flex;
    flex: 1;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: #ced4da;
    border: none;
    padding: 0.375rem 0.75rem;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-transform: none;

    &:disabled {
        pointer-events: none;
        opacity: 0.65;
        color: #6c757d;
        background-color: transparent;
    }

    &:hover {
        filter: brightness(75%);
    }
}

.itf-chained-form-buttton {
    display: block;
}

.itf-step-actions {
    .itf-button {
        font-size: 1.1875rem;
        color: #fff;
    }
}

.itf-button-reset {
    margin: 1rem auto;
    cursor: pointer;
    display: flex;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.83125rem;
    border-radius: 0.2rem;
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
}

.itf-button-retry {
    margin: auto;
}

.itf-button-parent-modal-close {
    position: absolute;
    top: 0;
    right: 1rem;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}

.itf-currency-input {
    display: flex;

    span {
        padding: 0.375rem 0.75rem;
        white-space: nowrap;
        background-color: #e9ecef;
        border-left: 1px solid #ced4da;
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        user-select: none;
    }

    input {
        margin-left: -1px;
        border-radius: 0 0.25rem 0.25rem 0;
    }
}

.itf-checkbox-input,
.itf-radio-input {
    width: 1em;
    height: 1em;
    line-height: 1.5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    vertical-align: middle;

    &:active {
        filter: brightness(90%);
    }

    &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }

    & + label {
        display: inline-block;
        margin-left: 0.4rem;
        vertical-align: middle;
    }
}

.itf-checkbox-input {
    border-radius: 0.25em;

    &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
}

.itf-radio-input {
    border-radius: 50%;

    &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }
}

.itf-multi-select,
.itf-radio-input {
    &:disabled ~ label {
        opacity: 0.5;
    }
}

.itf-select {
    display: block;
    width: 100%;
    line-height: 1.5;
    padding: 0.5rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    min-height: 1.425rem;
    word-wrap: normal;
    text-transform: none;
}

.itf-text-input {
    display: block;
    width: 100%;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

textarea.itf-text-input {
    resize: vertical;
}

.itf-button,
.itf-checkbox-input,
.itf-radio-input,
.itf-select,
.itf-text-input {
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
    }
}

.itf-checkbox-input,
.itf-radio-input,
.itf-select,
.itf-text-input {
    font-family: inherit;
    font-size: inherit;
    &:focus {
        background-color: #fff;
        border-color: #86b7fe;
    }

    &:disabled {
        background-color: #e9ecef;
    }

    &::placeholder {
        color: #6c757d;
        opacity: 1;
    }

    &:disabled {
        background-color: #e9ecef;
        opacity: 1;
        pointer-events: none;
        filter: none;
    }
}

.itf-ddl-other {
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.itf-multi-select-other {
    margin-left: $radio-note-inset;
}

.itf-radio-option-other {
    margin-left: $radio-note-inset;
}

.itf-option-other-ddl {
    margin-top: 0.5rem;
}

.itf-yes-no {
    display: flex;
    background-color: #eee;
    $w: 2.5rem;
    width: calc($w * 3);
    $br: 0.3rem;
    border-radius: $br;
    border: 1px solid #ced4da;
    margin-top: 0.25rem;

    div {
        width: $w;
        padding: 0.25rem;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }

    .disabled {
        opacity: 0.5;
    }

    .yes {
        border-top-left-radius: $br;
        border-bottom-left-radius: $br;

        &.selected {
            background-color: $intouch-brand-1;
            color: #fff;
            animation-duration: 0.4s;
            animation-name: slideYes;
        }
    }
    .null {
        &.disabled {
            background-color: #999;
        }
        &.selected {
            background-color: #bbb;
        }
    }

    .no {
        border-top-right-radius: $br;
        border-bottom-right-radius: $br;

        &.selected {
            background-color: $intouch-brand-1;
            color: #fff;
            animation-duration: 0.4s;
            animation-name: slideNo;
        }
    }

    @keyframes slideYes {
        from {
            transform: translate($w, 0);
        }
        to {
            transform: translate(0, 0);
        }
    }

    @keyframes slideNo {
        from {
            transform: translate(-$w, 0);
        }
        to {
            transform: translate(0, 0);
        }
    }
}
