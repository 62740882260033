/**********************************************************************
Media Queries
***********************************************************************/
@media (prefers-reduced-motion: no-preference) {
    .itf-container {
        scroll-behavior: smooth;
    }
}

@media (prefers-reduced-motion: reduce) {
    .itf-container {
        button,
        input,
        select,
        textarea {
            transition: none !important;
        }
    }
}

@media (max-width: 800px) {
    .itf-side-menu {
        display: none;
    }

    .itf-table {
        th,
        td {
            min-width: unset;
        }
    }
}
