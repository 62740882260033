.itf-table {
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
    caption-side: bottom;
    border-collapse: collapse;
    text-align: right;

    th:first-child,
    td:first-child {
        width: 100%;
        text-align: left;
    }

    th,
    td {
        padding: 0.5rem;
        min-width: 9rem;
        vertical-align: top;
    }
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }

    tfoot {
        border-top: 2px solid currentColor;
        font-weight: bold;
    }
}
