.itf-address-lookup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.itf-address-loader {
}

.itf-address-select {
}

.itf-address-button {
    margin-left: 1rem;
}

.itf-address-input + .itf-address-input {
    margin-top: 0.25rem;
}
