.signature-container {
    position: relative;

    canvas {
        border: dashed 2px #aaa;
    }

    button {
        position: absolute;
        top: 0.5rem;
        right: 0.4rem;
    }
}
