.itf-date-picker {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    min-width: 21rem;

    & + .itf-time-picker {
        margin-top: 1rem;
    }

    .itf-select {
        width: unset;
    }
}

.itf-month-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    // max-width: 34%;
}

.itf-day-select {
    border-radius: 0;
    // max-width: 33%;
}

.itf-year-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // max-width: 33%;
}

.itf-time-picker {
    .itf-year-select {
        border-radius: 0;
    }

    .itf-time-separator {
        display: flex;
        align-items: center;
        padding: 7px 0.75rem;
        font-size: 0.95rem;
        font-weight: 400;
        color: #212529;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
    }

    .itf-hour-select,
    .itf-minute-select {
        max-width: 6rem;
    }

    .itf-hour-select {
        border-radius: 0;
    }

    .itf-minute-select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.itf-date-time-summary {
    font-size: small;
    font-style: italic;
}
