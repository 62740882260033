.itf-confirm-action {
    position: absolute;
    font-size: large;
    top: 100%;
    z-index: 5;
    width: 90%;
    text-align: center;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}

.itf-debug {
    display: flex;
    gap: 1rem;
    padding: 2rem;
    > div {
        flex: 1;
        padding: 1rem;
        background: #212529;
        color: #f8f9fa;
        border-radius: 0.25rem;
        font-size: small;
    }
}

.itf-restored-answers {
    color: $attention-color;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    > .itf-button-reset {
        width: 10rem;
        flex: unset;
        margin: 1rem 0;
    }
}

.itf-error-message {
    margin: 3rem;
    color: $attention-color;
    text-align: center;
    font-size: 1.5rem;
}

.itfw-fee-notes {
    font-size: small;
}

.itf-loader {
    align-self: center;
    justify-self: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
}

.itf-spinner {
    margin: 0 0.5rem;
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
    display: inline-block;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1s linear infinite spin;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
