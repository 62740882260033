.itf-label {
    display: inline-block;
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-weight: 500;
    margin-bottom: 1rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    color: #0d6efd;
    text-decoration: underline;

    &:hover {
        color: #0a58ca;
    }
}
