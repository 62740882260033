/******************************************************************
Side Menu
*******************************************************************/
.itf-side-menu {
    margin: 1.5rem;
    padding: 1.5rem;
    box-shadow: 0 2px 5px 0 rgb(60 66 87 / 12%), 0 1px 1px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    background-color: #fafafa;
    width: 20rem;
    position: sticky;
    top: 1rem;
}

.itf-form-name {
    color: #6c757d;
}

.itf-step-list-item {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    $border-width: 0.25rem;
    border-bottom: solid transparent $border-width;
    padding-bottom: $border-width;

    &.is-valid {
        a {
            color: $intouch-brand-1 !important;
        }
    }
    &.is-invalid {
        a {
            color: $attention-color !important;
        }
    }

    a {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        text-decoration: none;
        font-size: 1.2rem;

        &:hover {
            color: lighten($intouch-brand-1, 5%);
        }

        > .itf-step-number {
            display: inline-block;
            align-items: start;
        }

        > .itf-step-label {
            display: inline-block;
            flex-grow: 1;
            padding-left: 0.25rem;
        }

        > .itf-step-icon {
            display: inline-block;
            align-items: flex-end;
        }
    }
}
